import * as React from "react"

// styles
const headerStyle = {
  boxSizing: 'border-box',
  backgroundColor: "black",
  color: "#232129",
  padding: 20,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  listStyleType: "none",
}
const listItemStyles = {
  marginBottom: 12,
  fontWeight: "300",
  letterSpacing: 1,
}
const linkStyles = {
  color: "#8954A8",
}
const containerStyles = {
  margin: "auto",
  width: "90%",
  maxWidth: "1000px",
}

// data
const links = [
  {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
  },
  {
    text: "Tutorials",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "Guides",
    url: "https://www.gatsbyjs.com/tutorial/",
  },
  {
    text: "API Reference",
    url: "https://www.gatsbyjs.com/docs/api-reference/",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
  },
  {
    text: "Cheat Sheet",
    url: "https://www.gatsbyjs.com/docs/cheat-sheet/",
  },
]

// markup
const TermsPage = () => {
  return (
    <div>
      <header style={headerStyle}>
        <title>Ta' Spiru Barber Shop</title>
        <img
          alt="Ta Spiru Logo"
          style={{width:'25%',minWidth:"100px",maxWidth:"100px",margin:'auto',display:"block"}}
          src={require("../images/taspirulogo.png")}
        />

      </header>

      <main style={containerStyles}>
          <h1>Terms & Conditions</h1>

            <h2>Introduction</h2>
            <p>Ta’ Together or individually, as the context in this Privacy Policy requires, we refer to the Ta’ Spiru apps and web properties as the “Ta’ Spiru Service”. This Privacy Policy (the “Privacy Policy”), which is compliant with the Data Protection Act and the General Data Protection Regulation (herein referred to as “the GDPR”) , sets out Ta’ Spiru’s privacy policy regarding the personal information which we may collect about you while you use the Ta’ Spiru Service. By using and/or accessing the Ta’ Spiru Service you consent to us processing your information for the purposes outlined in this Statement. </p>
            <p>In this policy, “the Company”, "we", "us" and "our" refer to Norbert Falzon trading as “Ta’ Spiru” holder of identity card number  183481(M), and operating from Ta' Spiru Head Office, 35b, Labour Avenue Naxxar, NXR 9021.</p>
            <h2>Personal Information Collected Through the Ta’ Spiru Service</h2>
            <p>In an effort to make it easier and more rewarding for you to use the Ta’ Spiru Service and access our services, when you register through the Ta’ Spiru Service, we collect certain personally identifiable information (collectively, the “Personal Information”). For example, we may ask for certain basic information to complete and submit your application for various payment options (paypal, debit cards, credit cards, bank account linking, debit card linking, etc.), or in order to service your Ta’ Spiru account. This information may include: your name, e-mail address, telephone number and payment information such as your bank account or credit card number. Additionally, Ta’ Spiru may request information about what you like and do not like about the Ta’ Spiru Service. By knowing your preferences, Ta’ Spiru may provide services and other special offerings on a personalized basis. Any data provided during the registration process, including information that is provided by or acquired through a third party site, will become part of your Personal Information to the extent it identifies you personally and will become information owned by Ta’ Spiru. Ta’ Spiru utilises IT security techniques that are industry standard to assure the privacy and security of any Personal Information you submit through the Ta’ Spiru Service. You understand that no security technology or practice is infallible (for futher information please see “Security of Personal Information” below).</p>
            <p>As permitted under applicable law, we may use the information we collect through the Ta’ Spiru Service for various other business purposes such as customer service, fraud prevention, market research, and improving our products and services. In addition, we may remove all the personally identifiable information from data we collect, and combine such anonymous data with other aggregated data compiled by Ta’ Spiru for historical, research or statistical purposes. Because such anonymous aggregated data does not include Personal Information from which the identity of an individual can be determined, Ta’ Spiru reserves the right to use aggregated data in any way it considers appropriate.</p>
            <p>You agree to receive advertising, promotional and other similar materials from Ta’ Spiru in print and/or electronic format. In compliance with applicable legistlation, you will be given the opportunity to unsubscribe from the Ta’ Spiru service. </p>
            <p>You may also receive notifications from Ta’ Spiru in print and/or electronic format relating to actions or inactions that affect your account, or information we believe you should have concerning your account. We refer to these as “transactional notifications” and they may be triggered by your actions, actions taken on your behalf, the actions of others, or changes in the status of your account. Examples include notifications of: an email address verification or a password reset, a triggered thank you, or a balance update. These notifications may be delivered in print or email or on a mobile device. While you may not have the opportunity to unsubscribe from transactional notifications, if you have questions about them, or you believe you are receiving notifications in error, please contact (insert email address).</p>
            <h2>Use of Personal Information</h2>
            <p>Any Personal Information that you submit to Ta’ Spiru will only be used within Ta’ Spiru’s domain. No Personal Information collected by Ta’ Spiru via the Ta’ Spiru Service will be sold or otherwise disclosed to third parties; provided, however, Ta’ Spiru reserves the right to disclose any information in its possession if it is required to do so by law, or if Ta’ Spiru determines that such disclosure is necessary to comply with applicable law, or to defend its rights or property, or to respond to an emergency situation. If Ta’ Spiru sells one of its product lines or divisions, your Personal Information may be transferred to the buyer so that the buyer can continue to provide you with information and services. If Ta’ Spiru provides your Personal Information to individuals and entities which assist us with our business activities, it is Ta’ Spiru’s practice to require those individuals and entities to keep your Personal Information confidential and to use your Personal Information only to perform functions for Ta’ Spiru.</p>
            <p>We may also use third parties to facilitate our business. In connection with these business operations, our service providers may have access to your Personal Information for use for a limited time in connection with these business activities. Where we utilize third parties for the processing of any Personal Information, we implement reasonable contractual and technical protections limiting the use of that information to the Ta’ Spiru-specified purposes.</p>
            <h2>Links</h2>
            <p>Ta’ Spiru may include links to other websites that will permit you to leave the Ta’ Spiru Service. Ta’ Spiru does not own, control or operate such links or their websites, and Ta’ Spiru is not responsible for the contents of any linked website or any link contained in a linked website. The inclusion of any link does not imply endorsement of the website, or any product or service. The use and/or privacy provided by the operator of a linked website as to any information you provide in accessing a linked website is in no way subject to this Policy. Each linked website may have its own policies that could differ considerably from this Policy, or the linked website may not display any privacy policy at all. In any event, even though a linked website may be accessed through the Ta’ Spiru Service, Ta’ Spiru cannot be responsible for any use that a linked website may make of your information.</p>
            <h2>Security of Personal Information</h2>
            <p>Ta’ Spiru is committed to protecting the security of your Personal Information. Personal data that we collect from you is stored within the European Economic Area (EEA) but may also be transferred to and processed in a country outside EU/EEA. We use a variety of security technologies to help protect your Personal Information that you provide to us against any unauthorized disclosure, misuse, loss or alteration. Your Personal Information, including your credit card and other financial account information, is encrypted as it travels over the internet using encryption that is standard or better than standard in the industry. Our employees with access to your Personal Information are trained to follow specific practices governing the proper handling of Personal Information, and we limit access to Personal Information only to those employees with a specific need to retrieve such information.</p>
            <h2>Minors</h2>
            <p>The Ta’ Spiru Service is directed to the general public. No one under the age of eighteen (18) should attempt to log in the Ta’ Spiru Service and/or submit any Personal Information to the Ta’ Spiru Service.</p>
            <h2>Data subject rights information</h2>
            <p>1. Your principal rights under data protection law, including the GDPR are:</p>
            <p>(a)   the right to access;</p>
            <p>(b)   the right to rectification;</p>
            <p>(c)   the right to erasure (the right to be forgotten);</p>
            <p>(d)   the right to restrict processing;</p>
            <p>(e)   the right to object to processing;</p>
            <p>(f)   the right to data portability;</p>
            <p>(g)   the right to complain to a supervisory authority; and</p>
            <p>(h)   the right to withdraw consent.</p>
            <p>2. You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee.</p>
            <p>3. You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed.</p>
            <p>4. In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims.</p>
            <p>5. In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defence of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your personal data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defence of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</p>
            <p>6. You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defence of legal claims.</p>
            <p>7. You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.</p>
            <p>8. If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work or the place of the alleged infringement.</p>
            <p>9. To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
            <p>10. You may exercise any of your rights in relation to your personal data by written notice to us or by sending an email on taspirubarbershop@gmail.com, in addition to the other stipulated methods.</p>
            <p>11.   Contact</p>
            <p>You can contact us:</p>
            <p>(a)   by post, to the postal address given above; or</p>
            <p>(b)   by email.</p>
            <h2>Terms of Service</h2>
            <p>Your use of Ta’ Spiru, and any disputes arising from it, is subject to this Privacy Policy available as well as our User Agreement available at <a href="https://taspiru.com/terms">https://taspiru.com/terms</a> and including all of its dispute resolution provisions including arbitration, limitation on damages and choice of law.</p>
            <h2>Right to Update</h2>
            <p>Ta’ Spiru reserves the right to amend this Privacy Policy without prior notice to reflect technological advancements, legal and regulatory changes and good business practices. If Ta’ Spiru changes its privacy practices, a new Privacy Policy will reflect those changes and the effective date of the revised Privacy Policy will be set forth in this page.</p>
            <p></p>
      </main>
      <footer style={{padding: 10}}>
        <p style={{fontSize:"12px"}}>Google Play and the Google Play logo are trademarks of Google LLC.</p>
      </footer>
    </div>
  )
}

export default TermsPage
